<template>
  <div class="brand-container flex">
    <div class="left-menu">
      <div class="left-title fs-28">公益产品</div>
      <div class="menu-list">
        <div
          class="menu-item fs-18"
          :class="item.active ? 'active' : ''"
          v-for="(item, index) in brandList"
          :key="index"
          @click="chooseBrand(item.miniName, index)"
        >{{item.name}}</div>
      </div>
    </div>
    <div class="right-content">
      <div class="rich-text-content">
        <div class="rich-text fs-16 fc-3" v-html="currentHtml"></div>
        <div class="img-list" v-show="currentImgList.length > 0">
          <div class="img-item" v-for="(item, index) in currentImgList" :key="index">
            <img :src="item.src" class="brand-img" />
            <p>{{item.des}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { BRAND_LIST } from "@/static/brandList";

export default {
  name: "brandIndex",
  data() {
    return {
      choosedIndex: 0,
      currentImgList: [],
      brandList: BRAND_LIST,
      brandName: BRAND_LIST[0].miniName,
      currentHtml: ""
    };
  },
  mounted() {
    const { brandName = BRAND_LIST[0].miniName, index = 0 } = this.$route.query;
    this.choosedIndex = index;
    this.currentImgList = [];
    this.chooseBrand(brandName, index);
  },
  methods: {
    chooseBrand(brandName, index) {
      $("html, body").animate(
        {
          scrollTop: 0
        },
        0
      );
      this.brandName = brandName;
      this.currentHtml = this.brandList[index].html;
      this.brandList.map((cur, curIndex) => {
        cur.active = cur.miniName === brandName;
        return cur;
      });
      this.currentImgList = this.brandList[index].imgList;
    }
  }
};
</script>

<style lang="less" scoped>
.brand-container {
  padding: 0.56rem 2.5rem;
  position: relative;
  .left-menu {
    position: fixed;
    top: 1.68rem;
    .left-title {
      background: #f5f5f5;
      color: #c2956b;
      padding: 0.22rem 0.61rem;
    }
    .menu-list {
      .menu-item {
        background: #f5f5f5;
        color: #666;
        padding: 0.1rem 0.15rem;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s;
        &.active {
          background: #c2956b;
          color: #fff;
        }
        &:hover {
          background: #c2956b;
          color: #fff;
        }
      }
    }
  }
  .right-content {
    min-height: 4rem;
    padding-left: 2.6rem;
    text-indent: 0.32rem;
    .rich-text {
      p {
        padding: 0.2rem 0;
      }
    }
    .img-list {
      display: flex;
      flex-direction: column;
      .brand-img {
        width: 100%;
        margin-top: 0.2rem;
        border-radius: 0.04rem;
        display: block;
      }
      p {
        font-size: 0.16rem;
        color: #333;
        padding: 0.1rem;
        text-align: center;
      }
    }
  }
}
</style>